<template>
  <v-container>
    <v-row class="text-center">
      <p class="text-h4">News</p>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-timeline dense class="subtitle-1 font-weight-regular">
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Feb. 2024.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                <a
                  class="text-decoration-none"
                  href="https://github.com/iMoonLab/DeepHypergraph/releases/tag/v0.9.4"
                  target="_blank"
                  >v0.9.4</a
                > of
                <a
                  class="text-decoration-none"
                  href="http://deephypergraph.com/"
                  target="_blank"
                  >DHG</a
                > is released. We add 6 hypergraph datasets and fix some known bugs.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Jan. 2024.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                Two paper has been accpted by ICLR 2024.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Dec. 2023.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accpted by IEEE T-PAMI 2024.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Nov. 2023.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accpted by IEEE T-PAMI 2023.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Oct. 2023.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                I was invited to give a talk on
                <a
                  class="text-decoration-none"
                  href="https://mp.weixin.qq.com/s/2mSlWBu7NYo88SjFD8Wn8Q"
                  target="_blank"
                >
                  PRCV 2023</a
                >
                about Hypergraph Computation: Methods and Applications.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Oct. 2023.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accpted by IEEE TNNLS 2023.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Dec. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                <a
                  class="text-decoration-none"
                  href="https://github.com/iMoonLab/DeepHypergraph/releases/tag/v0.9.3"
                  target="_blank"
                  >v0.9.3</a
                > of
                <a
                  class="text-decoration-none"
                  href="http://deephypergraph.com/"
                  target="_blank"
                  >DHG</a
                > is released. We add a hypergraph dataset, fix some known bugs, and add some hypergraph operation.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Sep. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                <a
                  class="text-decoration-none"
                  href="https://github.com/iMoonLab/DeepHypergraph/releases/tag/v0.9.2"
                  target="_blank"
                  >v0.9.2</a
                > of
                <a
                  class="text-decoration-none"
                  href="http://deephypergraph.com/"
                  target="_blank"
                  >DHG</a
                > is released. We add 21 datasets, 6 SOTA methods, and structure and feature visualizations.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Sep. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accpted by IEEE T-PAMI 2022.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Sep. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                We have given a talk on
                <a
                  class="text-decoration-none"
                  href="https://www.micc.unifi.it/3dor2022/"
                  target="_blank"
                >
                  3DOR 2022</a
                >
                about Open-Set 3D Object Retrieval.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Aug. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                We have released the first version <a
                  class="text-decoration-none"
                  href="https://github.com/iMoonLab/DeepHypergraph/releases/tag/v0.9.1"
                  target="_blank"
                  >v0.9.1</a
                > of
                <a
                  class="text-decoration-none"
                  href="http://deephypergraph.com/"
                  target="_blank"
                  >DeepHypergraph (DHG)</a
                >.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Jul. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accepted by Computers & Graphics 2022.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Jun. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                We have developed an online multi-modal 3D object retrieval
                system. Welcome to
                <a href="https://moon-lab.tech/vors" target="_blank">play</a>.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                May. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                One paper has been accpted by IEEE T-PAMI 2022.
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small fill-dot>
            <v-row dense no-gutters>
              <v-col
                md="2"
                cols="12"
                class="grey--text text--darken-2 font-weight-medium font-italic"
              >
                Feb. 2022.</v-col
              >
              <v-col md="10" cols="12" class="align-self-center">
                We organize a track "<a
                  class="text-decoration-none"
                  href="https://www.moon-lab.tech/shrec22"
                  target="_blank"
                  >Open-Set 3D Object Retrieval</a
                >" in SHREC'22.
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MyNews",

  data: () => ({}),
};
</script>
