<template>
  <v-container dense>
    <v-row class="text-center">
      <p class="text-h4">Services</p>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-h6">
        Program Committee Member / Reviewer:
      </v-col>
      <v-col cols="12" class="text-body-1">
        <p>- Computer Vision and Pattern Recognition Conference 2024 (CVPR 2024)</p>
        <p>- CAAI International Conference on Artificial Intelligence 2022 (CICAI 2022)</p>
        <p>- International Conference on Medical Image Computing and Computer Assisted Intervention (MICCAI 2020)</p>
        <p>- International Joint Conference on Artificial Intelligence (IJCAI 2020)</p>
        <p>- IEEE International Conference on Image Processing (ICIP 2020)</p>
        <p>- Pacific-Rim Conference on Multimedia (PCM 2018)</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-h6"> Reviewer for Journals: </v-col>
      <v-col cols="12" class="text-body-1">
        <p>- IEEE Transactions on Pattern Analysis and Machine Intelligence</p>
        <p>- International Journal of Computer Vision</p>
        <p>- Medical Image Analysis</p>
        <p>- IEEE Transactions on Knowledge Discovery in Data</p>
        <p>- Journal of Visual Communication and Image Representation</p>
        <p>- IEEE Signal Processing Letters</p>
        <p>- Neurocomputing</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-h6"> Talk: </v-col>
      <v-col cols="12" class="text-body-1">
        <p>
          - Tutorial on Hypergraph Learning: Methods, Tools and Applications in
          Medical Image Analysis (MICCAI 2019)
        </p>
        <p>
          - Hypergraph Computation: Methods and Applications (PRCV 2023)
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-h6"> Challenge Organizer: </v-col>
      <v-col cols="12" class="text-body-1">
        <p>- SHREC'22 Track: Open-Set 3D Object Retrieval</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MyService",

  data: () => ({}),
};
</script>
